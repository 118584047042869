const ProjectInfos = [
    {
        'img': '/images/mynt.png',
        'name': 'MYNT',
        'desc': 'Landing page design for a financial services company that specializes in tracking investments. (24 Hour Hackathon Winner)',
        'stack': 'React, Nodejs, HTML/CSS'
    },
    // {
    //     'img': '/images/Portfolio.png',
    //     'name': 'HERMES',
    //     'desc': 'Frontend design for a recipe/food delivery service.',
    //     'stack': 'Nodejs, Express, HTML/CSS'
    // }
]

export default ProjectInfos;