// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import MainPage from './pages/MainPage/MainPage';

function App() {
  return (
    <MainPage />
  );
}

export default App;
